import React from "react"
import P from "../../core/P"

export const CardGridBGImageBlock = ({ CardGridBGImageBlock }) => {
  const { gridCards, gridSize, position } = CardGridBGImageBlock
  let className = ""
  if (position === "Center width") {
    className = "lg:pl-64 lg:pr-64"
  }
  if (position === "Full width") {
    className = "lg:pl-32 lg:pr-32"
  }
  if (position === "Wide width") {
    className = ""
  }

  let titleTextSize=""
  if (gridSize===5) {
    titleTextSize="lg:text-xl p-4 text-4xl xl:text-xl"
  }
  if (gridSize===4) {
    titleTextSize="lg:text-3xl md:text-4xl xl:text-4xl 2xl:text-5xl 2xl:px-8 2xl:pb-4 px-4 bold text-4xl"
  }
  if (gridSize===3) {
    titleTextSize="lg:text-5xl p-4 text-4xl"
  }

  const [current, setCurrent] = React.useState()
  React.useEffect(() => {
    setCurrent(window.location.pathname)
  }, [])

  const Section = e => (
    <div className="flex justify-center flex-col overflow-hidden relative kustom">
      <div className="flex-shrink-0 ">
        <div
          className={`${e?.description ? "" : "h-48"
            }  lg:min-h-full md:h-full w-full h-48 bg-black block z-10`}
        >
          <img
            className={`${e?.description ? "h-full" : "lg:h-full h-48"
              }  w-full object-cover z-0 opacity-25 `}
            src={e?.backgroundImage?.mediaItemUrl}
            alt={
              e?.backgroundImage?.altText
                ? e?.backgroundImage?.altText
                : e?.title
            }
            height={e?.backgroundImage?.mediaDetails?.height}
            width={e?.backgroundImage?.mediaDetails?.width}
            // title={e?.title}
            loading="lazy"
          />
        </div>
      </div>

      <div
          className="bg-transparent w-full text-center absolute mx-auto top-auto inline-block"
          data-aos="fade-up"
        >
          {e?.title && (
            <P
              title={e?.title}
              className={`${titleTextSize} font-serif font-semibold tracking-normal leading-10 text-white  w-full`}
              noPad
            />
          )}
         {e?.description && (
            <P
              className={`${gridSize===4?"lg:text-lg lg:px-2 md:text-xl xl:text-xl xl:px-6 2xl:text-2xl 2xl:px-8":"lg:text-xl lg:px-6"} md:text-sm  leading-7 text-white  lg:py-3 p-3 ${current === "/gitex-technology-week/"
                ? "block"
                : "hidden lg:block md:block"
                }`}
              noPad
              title={e?.description}
            />
          )}
        </div>
    </div>
  ) 
  return (
    <div className="relative bg-transparent pt-5 pb-20 lg:pb-28">
      <div
        className={`grid mx-auto md:w-full md:grid-cols-2 lg:max-w-none lg:grid-cols-${gridSize} ${className}`}
      >
        {gridCards.map(
          (e) => (
            e?.onClickLink?.uri ? (
              <a
                href={e?.onClickLink?.uri ? e?.onClickLink.uri : "#"}
                key={Math.random()}
              >
                {Section(e)}

              </a>
            ) : (
              <>{Section(e)}</>
            )
          )
        )}
      </div>
    </div>
  )
}

export default CardGridBGImageBlock
